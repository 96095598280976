<template>
	<div>
		<div style="" v-if="windowWidth>=1200">
			<div class="specialProcessesTop">
				<div class="sp_titleDiv">
					<div class="sp_td_title">{{$t('boardMaking.specialProcesses.title1')}}</div>
					<div class="sp_td_text">{{$t('boardMaking.specialProcesses.text1')}}</div>
				</div>
				<div class="sp_contentDiv">
					<div class="sp_cd_left">
						<div style="">
							<div class="sp_cd_le_title">{{$t('boardMaking.specialProcesses.title2')}}</div>
							<div class="sp_cd_le_text">{{$t('boardMaking.specialProcesses.text2')}}</div>
						</div>
						<div class="sp_cd_le_dataList">
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index1.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content1')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index2.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content2')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index3.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content3')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index4.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content4')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index5.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content5')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index6.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content6')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index7.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content7')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index8.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content8')}}</div>
							</div>
						</div>
					</div>
					
					<div class="sp_cd_right" :style="'background-image:' + 'url(' + Image + ')'">
						<el-image class="sp_cd_ri_img1" :src="Image3" :fit="'fill'"></el-image>
						<el-image class="sp_cd_ri_img2" :src="Image2" :fit="'fill'"></el-image>
					</div>
				</div>
				
				<div class="sp_button" style="cursor: pointer;" @click="jump">
					<div class="sp_bu_text">{{$t('boardMaking.specialProcesses.button')}}</div>
				</div>
			</div>
			
			<div class="specialProcessesBottom">
				<div style="display: flex;">
					<div class="spb_title">{{$t('boardMaking.specialProcesses.title3')}}</div>
					<div class="spb_text">{{$t('boardMaking.specialProcesses.text3')}}</div>
				</div>
				
				<div class="spb_tabs">
					<div class="spb_tb_left">
						<div class="spb_tb_le_text" v-for="(item,index) in $t('boardMaking.specialProcesses.tabs')" :key="index" :style="tabsIndex==index ? 'color: #fff;background: #d1102d;' : ''"  @mouseenter="tabsClick(index)">{{item.name}}</div>
					</div>
					<div class="spb_tb_right">
						<div class="spb_tb_ri_text">← {{$t('boardMaking.specialProcesses.text4')}}</div>
					</div>
					<div class="spb_tb_ri_background"></div>
				</div>
				
				<div class="spb_contentDiv">
					<div class="spb_cd_left" :style="'background-image:' + `url(${require('@/assets/img/select-bg.png')})`" v-for="(item,index) in $t('boardMaking.specialProcesses.tabs')" :key="index" v-if="tabsIndex == index">
						<div class="spb_cd_le_listDiv">
							<div class="spb_cd_le_ld_dataList" v-for="(item2,index2) in item.list" :key="index2">
								<div class="spb_cd_le_ld_dl_name">{{item2.name}}</div>
								<div class="spb_cd_le_ld_dl_text">{{item2.text}}</div>
							</div>
						</div>
						<el-image class="spb_cd_le_img" :src="item.img" :fit="'fill'"></el-image>
					</div>
					
					<div class="spb_cd_right">
						<div class="spb_cd_ri_listDiv" :style="'background-image:' + `url(${$t('boardMaking.specialProcesses.textImage1')})`">
							<div class="spb_cd_ri_ld_div">
								<div style="width: 100%;">
									<div class="spb_cd_ri_ld_di_text">{{$t('boardMaking.specialProcesses.text5')}}<div class="spb_cd_ri_ld_di_icon">→</div></div>
								</div>
							</div>
						</div>
						
						<div class="spb_cd_ri_listDiv" :style="'background-image:' + `url(${$t('boardMaking.specialProcesses.textImage2')})`">
							<div class="spb_cd_ri_ld_div">
								<div style="width: 100%;">
									<div class="spb_cd_ri_ld_di_text">{{$t('boardMaking.specialProcesses.text6')}}<div class="spb_cd_ri_ld_di_icon">→</div></div>
								</div>
							</div>
						</div>
						
						<div class="spb_cd_ri_listDiv" :style="'background-image:' + `url(${$t('boardMaking.specialProcesses.textImage3')})`">
							<div class="spb_cd_ri_ld_div">
								<div style="width: 100%;">
									<div class="spb_cd_ri_ld_di_text">{{$t('boardMaking.specialProcesses.text7')}}<div class="spb_cd_ri_ld_di_icon">→</div></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div style="display: flex;justify-content: center;padding-top: 20px;cursor: pointer;" @click="jump">
					<div style="color: #fff;font-size: 1vw;padding: 0.5vw;border-radius: 40px;text-align: center;margin-top: 20px;background: #d1102d;background-blend-mode: normal;box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);width: 9vw;">{{$t('boardMaking.specialProcesses.button')}}</div>
				</div>
			</div>
		</div>
		
		<div style="" v-if="windowWidth<=1199">
			<div class="specialProcessesTop2">
				<div class="sp_titleDiv">
					<div class="sp_td_title">{{$t('boardMaking.specialProcesses.title1')}}</div>
					<div class="sp_td_text">{{$t('boardMaking.specialProcesses.text1')}}</div>
				</div>
				<div class="sp_contentDiv">
					<div class="sp_cd_left">
						<div style="">
							<div class="sp_cd_le_title">{{$t('boardMaking.specialProcesses.title2')}}</div>
							<div class="sp_cd_le_text">{{$t('boardMaking.specialProcesses.text2')}}</div>
						</div>
						<div class="sp_cd_le_dataList">
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index1.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content1')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index2.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content2')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index3.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content3')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index4.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content4')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index5.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content5')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index6.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content6')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index7.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content7')}}</div>
							</div>
							<div class="sp_cd_le_dl_div">
								<el-image class="sp_cd_le_dl_di_img" :src="require('@/assets/img/index8.png')" :fit="'fill'"></el-image>
								<div class="sp_cd_le_dl_di_content">{{$t('boardMaking.specialProcesses.content8')}}</div>
							</div>
						</div>
					</div>
					
					<div class="sp_cd_right">
						<div style="position: relative;">
							<el-image class="sp_cd_ri_img1" :src="Image3" :fit="'fill'"></el-image>
							<el-image class="sp_cd_ri_img2" :src="Image2" :fit="'fill'"></el-image>
							<el-image class="sp_cd_ri_img3" :src="Image" :fit="'fill'"></el-image>
						</div>
					</div>
				</div>
				
				<div class="sp_button" style="cursor: pointer;" @click="jump">
					<div class="sp_bu_text">{{$t('boardMaking.specialProcesses.button')}}</div>
				</div>
			</div>
			
			<div class="specialProcessesBottom2">
				<div style="">
					<div class="spb_title">{{$t('boardMaking.specialProcesses.title3')}}</div>
					<div class="spb_text">{{$t('boardMaking.specialProcesses.text3')}}</div>
				</div>
				
				<div class="spb_tabs">
					<div class="spb_tb_left">
						<div class="spb_tb_le_text" v-for="(item,index) in $t('boardMaking.specialProcesses.tabs')" :key="index" :style="tabsIndex==index ? 'color: #fff;background: #d1102d;' : ''"  @mouseenter="tabsClick(index)">{{item.name}}</div>
					</div>
				</div>
				
				<div class="spb_contentDiv">
					<div class="spb_cd_left" :style="'background-image:' + `url(${require('@/assets/img/select-bg.png')})`" v-for="(item,index) in $t('boardMaking.specialProcesses.tabs')" :key="index" v-if="tabsIndex == index">
						<div class="spb_cd_le_listDiv">
							<div class="spb_cd_le_ld_dataList" v-for="(item2,index2) in item.list" :key="index2">
								<div class="spb_cd_le_ld_dl_name">{{item2.name}}</div>
								<div class="spb_cd_le_ld_dl_text">{{item2.text}}</div>
							</div>
						</div>
						<el-image class="spb_cd_le_img" :src="item.img" :fit="'fill'"></el-image>
					</div>
				</div>
				
				<div style="display: flex;justify-content: center;padding-top: 20px;cursor: pointer;" @click="jump">
					<div style="color: #fff;font-size: 12px;padding: 10px 20px;border-radius: 40px;text-align: center;margin-top: 20px;background: #d1102d;background-blend-mode: normal;box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);width: 100px;">{{$t('boardMaking.specialProcesses.button')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabs:[{
					name:"罗杰斯"
				},
				{
					name:"铜基板"
				},
				{
					name:"双面铝基板"
				},
				{
					name:"FPC软板"
				},
				{
					name:"软硬结合板"
				}],
				tabsIndex:0,//tabs下标
				Image:process.env.VUE_APP_OSS_URL + '/boardMaking/temp2.jpg',
				Image2:process.env.VUE_APP_OSS_URL + '/boardMaking/current-move.png',
				Image3:process.env.VUE_APP_OSS_URL + '/boardMaking/customized-img-bg.png'
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//----------------------tabs切换事件------------------------
			tabsClick:function(index){
				this.tabsIndex=index;
			},
			
			//-----------------路由跳转-----------------------
			jump:function(){
				this.$router.push({
					path: '/valuation',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.specialProcessesTop{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 28px;
					}
					
					.sp_cd_le_text{
						color: #626262;
						line-height:2.5vw;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 50%;
							align-items: center;
							line-height: 40px;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 2.6vw;
								height: 1.9vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 1vw;
							}
						}
					}
				}
				
				.sp_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						width: 9vw;
						height: 100%;
						z-index: 2;
					}
					
					.sp_cd_ri_img2{
						width: 10vw;
						height: 5vw;
						z-index: 4;
						position: absolute;
						right: 4vw;
						top: 2vw;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
		
		.specialProcessesBottom{
			width: 100%;
			padding: 40px 200px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 28px;
			}
			
			.spb_text{
				color: #626262;
				line-height:2.5vw;
				padding-left: 10px;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 70%;
					z-index: 4;
					
					.spb_tb_le_text{
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.8vw;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 40px;
				z-index: 4;
				
				.spb_cd_left{
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							width: 40vw;
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.specialProcessesTop{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 28px;
					}
					
					.sp_cd_le_text{
						color: #626262;
						line-height:2.5vw;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 50%;
							align-items: center;
							line-height: 40px;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 2.6vw;
								height: 1.9vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 1vw;
							}
						}
					}
				}
				
				.sp_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						width: 9vw;
						height: 100%;
						float: right;
						z-index: 2;
					}
					
					.sp_cd_ri_img2{
						width: 10vw;
						height: 5vw;
						z-index: 4;
						position: absolute;
						right: 4vw;
						top: 2vw;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
		
		.specialProcessesBottom{
			width: 100%;
			padding: 40px 200px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 28px;
			}
			
			.spb_text{
				color: #626262;
				line-height:2.5vw;
				padding-left: 10px;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 70%;
					z-index: 4;
					
					.spb_tb_le_text{
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.8vw;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 40px;
				z-index: 4;
				
				.spb_cd_left{
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							width: 40vw;
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.specialProcessesTop{
			padding: 40px 140px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 28px;
					}
					
					.sp_cd_le_text{
						color: #626262;
						line-height:2.5vw;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 50%;
							align-items: center;
							line-height: 40px;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 2.6vw;
								height: 1.9vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 1vw;
							}
						}
					}
				}
				
				.sp_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						width: 9vw;
						height: 100%;
						float: right;
						z-index: 2;
					}
					
					.sp_cd_ri_img2{
						width: 10vw;
						height: 5vw;
						z-index: 4;
						position: absolute;
						right: 4vw;
						top: 2vw;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
		
		.specialProcessesBottom{
			width: 100%;
			padding: 40px 140px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 28px;
			}
			
			.spb_text{
				color: #626262;
				line-height:2.5vw;
				padding-left: 10px;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 70%;
					z-index: 4;
					
					.spb_tb_le_text{
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.8vw;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 40px;
				z-index: 4;
				
				.spb_cd_left{
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							width: 40vw;
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.specialProcessesTop{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 28px;
					}
					
					.sp_cd_le_text{
						color: #626262;
						line-height:2.5vw;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 50%;
							align-items: center;
							line-height: 40px;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 2.6vw;
								height: 1.9vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 1vw;
							}
						}
					}
				}
				
				.sp_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						width: 9vw;
						height: 100%;
						float: right;
						z-index: 2;
					}
					
					.sp_cd_ri_img2{
						width: 10vw;
						height: 5vw;
						z-index: 4;
						position: absolute;
						right: 4vw;
						top: 2vw;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
		
		.specialProcessesBottom{
			width: 100%;
			padding: 40px 30px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 28px;
			}
			
			.spb_text{
				color: #626262;
				line-height:2.5vw;
				padding-left: 10px;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 70%;
					z-index: 4;
					
					.spb_tb_le_text{
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.8vw;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 40px;
				z-index: 4;
				
				.spb_cd_left{
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							width: 40vw;
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.specialProcessesTop2{
			padding: 0 30px 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				width: 100%;
				height: 100%;
				
				.sp_cd_left{
					padding-top: 20px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 21px;
					}
					
					.sp_cd_le_text{
						color: #626262;
						line-height:2.5vw;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 50%;
							align-items: center;
							line-height: 40px;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 2.6vw;
								height: 1.9vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 1vw;
							}
						}
					}
				}
				
				.sp_cd_right{
					position: relative;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						bottom: 0;
						position: absolute;
						right: 0;
						height: 100%;
						z-index: 4;
					}
					
					.sp_cd_ri_img2{
						width: 15vw;
						height: 8vw;
						z-index: 5;
						position: absolute;
						right: 5vw;
						top: 4vw;
					}
					
					.sp_cd_ri_img3{
						z-index: 3;
						left: 0;
						top: 0;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 12px;
					padding: 10px;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 100px;
				}
			}
		}
		
		.specialProcessesBottom2{
			width: 100%;
			padding: 40px 30px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 21px;
			}
			
			.spb_text{
				color: #626262;
				line-height:3vw;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 20px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 100%;
					z-index: 4;
					flex-wrap: wrap;
					justify-content: center;
					
					.spb_tb_le_text{
						padding: 10px 12px;
						font-weight: bold;
						font-size: 12px;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 20px;
				z-index: 4;
				
				.spb_cd_left{
					width: 100%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							width: 40vw;
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.specialProcessesTop2{
			padding: 0 30px 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 16px;
					color: #626262;
					padding-top: 10px;
				}
			}
			
			.sp_contentDiv{
				width: 100%;
				height: 100%;
				
				.sp_cd_left{
					padding-top: 20px;
					
					.sp_cd_le_title{
						color: #333333;
						font-weight: bold;
						font-size: 21px;
						text-align: center;
					}
					
					.sp_cd_le_text{
						color: #626262;
						text-align: center;
						padding-top: 10px;
					}
					
					.sp_cd_le_dataList{
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						padding-top: 20px;
						
						.sp_cd_le_dl_div{
							display: flex;
							width: 40%;
							align-items: center;
							margin-top: 20px;
							
							.sp_cd_le_dl_di_img{
								width: 4vw;
								height: 2.5vw;
							}
							
							.sp_cd_le_dl_di_content{
								padding-left: 10px;
								font-size: 12px;
							}
						}
					}
				}
				
				.sp_cd_right{
					position: relative;
					margin-top: 20px;
					
					.sp_cd_ri_img1{
						bottom: 0;
						position: absolute;
						right: 0;
						height: 100%;
						z-index: 4;
					}
					
					.sp_cd_ri_img2{
						width: 15vw;
						height: 8vw;
						z-index: 5;
						position: absolute;
						right: 5vw;
						top: 4vw;
					}
					
					.sp_cd_ri_img3{
						z-index: 3;
						left: 0;
						top: 0;
					}
				}
			}
			
			.sp_button{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.sp_bu_text{
					color: #fff;
					font-size: 12px;
					padding: 10px;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background: #d1102d;
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 100px;
				}
			}
		}
		
		.specialProcessesBottom2{
			width: 100%;
			padding: 40px 30px;
			background: #f5f5f5;
			
			.spb_title{
				color: #333333;
				font-weight: bold;
				font-size: 21px;
			}
			
			.spb_text{
				color: #626262;
				line-height:3vw;
			}
			
			.spb_tabs{
				display: flex;
				margin-top: 20px;
				align-items: center;
				position: relative;
				
				.spb_tb_left{
					display: flex;
					width: 100%;
					z-index: 4;
					flex-wrap: wrap;
					justify-content: center;
					
					.spb_tb_le_text{
						padding: 10px 12px;
						font-weight: bold;
						font-size: 12px;
						cursor: pointer;
					}
				}
				
				.spb_tb_right{
					width: 30%;
					text-align: right;
					z-index: 4;
					
					.spb_tb_ri_text{
						font-weight: bold;
						font-size: 1vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
				
				.spb_tb_ri_background{
					position: absolute;
					width: 24%;
					height: 16vw;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
			
			.spb_contentDiv{
				display: flex;
				margin-top: 20px;
				z-index: 4;
				
				.spb_cd_left{
					width: 100%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background-size: cover;
					
					.spb_cd_le_listDiv{
						z-index: 6;
						position: relative;
						
						.spb_cd_le_ld_dataList{
							padding-bottom: 20px;
							
							.spb_cd_le_ld_dl_name{
								font-weight: bold;
								color: #d1102d;
							}
							
							.spb_cd_le_ld_dl_text{
								font-weight: bold;
								color: #fff;
								line-height: 30px;
								text-shadow: 1px 2px 2px #000;
							}
						}
					}
					
					.spb_cd_le_img{
						height: 100%;
						z-index: 5;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
					}
				}
				
				.spb_cd_right{
					width: 20%;
					padding: 0 2vw;
					
					.spb_cd_ri_listDiv{
						height: 31.4%;
						width: 100%;
						background-size: cover;
						position: relative;
						
						.spb_cd_ri_ld_div{
							padding: 0.5vw;
							background: #fff;
							position: absolute;
							width: 100%;
							bottom: 0;
							
							.spb_cd_ri_ld_di_text{
								font-size: 0.5vw;
								
								.spb_cd_ri_ld_di_icon{
									font-size: 0.5vw;
									float: right;
								}
							}
						}
					}
				}
			}
		}
	}
</style>