<template>
	<div>
		<div class="selectKoiios" v-if="windowWidth>=1200">
			<div class="sk_title">{{$t('boardMaking.selectKoiios.title')}}</div>
			<div class="sk_contentTop" :style="'background-image:' + `url(${require('@/assets/img/why-us-bg.jpg')})`">
				<div class="sk_ct_contentDiv">
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text1')}}</div>
						<div class="sk_ct_cd_di_text">200+</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text2')}}</div>
						<div class="sk_ct_cd_di_text">650,000+</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text3')}}</div>
						<div class="sk_ct_cd_di_text">80,000㎡</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text4')}}</div>
						<div class="sk_ct_cd_di_text">3,000+</div>
					</div>
				</div>
				
				<div class="sk_ct_imgListDiv">
					<div class="sk_ct_ild_div">
						<el-image class="sk_ct_ild_di_img" :src="require('@/assets/img/why-product-l.png')" :fit="'fill'"></el-image>
						<div class="sk_ct_ild_di_content">{{$t('boardMaking.selectKoiios.text5')}}</div>
					</div>
					<div class="sk_ct_ild_div">
						<el-image class="sk_ct_ild_di_img" :src="require('@/assets/img/environment1.jpg')" :fit="'fill'"></el-image>
						<!-- <div class="sk_ct_ild_di_content">{{$t('boardMaking.selectKoiios.text6')}}</div> -->
					</div>
				</div>
				
				<div class="sk_ct_button" style="cursor: pointer;" @click="jump">
					<div class="sk_ct_bu_text">{{$t('boardMaking.selectKoiios.button')}}</div>
				</div>
			</div>
		</div>
		
		<div class="selectKoiios2" v-if="windowWidth<=1199">
			<div class="sk_title">{{$t('boardMaking.selectKoiios.title')}}</div>
			<div class="sk_contentTop" :style="'background-image:' + `url(${require('@/assets/img/why-us-bg.jpg')})`">
				<div class="sk_ct_contentDiv">
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text1')}}</div>
						<div class="sk_ct_cd_di_text">200+</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text2')}}</div>
						<div class="sk_ct_cd_di_text">650,000+</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text3')}}</div>
						<div class="sk_ct_cd_di_text">80,000㎡</div>
					</div>
					<div class="sk_ct_cd_div">
						<div class="sk_ct_cd_di_title">{{$t('boardMaking.selectKoiios.text4')}}</div>
						<div class="sk_ct_cd_di_text">3,000+</div>
					</div>
				</div>
				
				<div class="sk_ct_imgListDiv">
					<div class="sk_ct_ild_div">
						<el-image class="sk_ct_ild_di_img" :src="require('@/assets/img/why-product-l.png')" :fit="'fill'"></el-image>
						<div class="sk_ct_ild_di_content">{{$t('boardMaking.selectKoiios.text5')}}</div>
					</div>
					<div class="sk_ct_ild_div">
						<el-image class="sk_ct_ild_di_img" :src="require('@/assets/img/environment1.jpg')" :fit="'fill'"></el-image>
						<!-- <div class="sk_ct_ild_di_content">{{$t('boardMaking.selectKoiios.text6')}}</div> -->
					</div>
				</div>
				
				<div class="sk_ct_button" style="cursor: pointer;" @click="jump">
					<div class="sk_ct_bu_text">{{$t('boardMaking.selectKoiios.button')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//-----------------路由跳转-----------------------
			jump:function(){
				this.$router.push({
					path: '/aboutUs/aboutUs',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.selectKoiios{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 200px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 48%;
						position: relative;
						margin-right: 2%;
						
						.sk_ct_ild_di_img{
							width: 100%;
							height: 20vw;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 1vw;
						padding: 0.5vw;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 9vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.selectKoiios{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 200px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 48%;
						position: relative;
						margin-right: 2%;
						
						.sk_ct_ild_di_img{
							width: 100%;
							height: 20vw;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 1vw;
						padding: 0.5vw;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 9vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.selectKoiios{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 140px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 48%;
						position: relative;
						margin-right: 2%;
						
						.sk_ct_ild_di_img{
							width: 100%;
							height: 20vw;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 1vw;
						padding: 0.5vw;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 9vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.selectKoiios{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 30px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 48%;
						position: relative;
						margin-right: 2%;
						
						.sk_ct_ild_di_img{
							width: 100%;
							height: 20vw;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 1vw;
						padding: 0.5vw;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 9vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.selectKoiios2{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 30px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 100%;
						position: relative;
						margin-bottom: 20px;
						
						.sk_ct_ild_di_img{
							width: 100%;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 12px;
						padding: 10px;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.selectKoiios2{
			
			.sk_title{
				padding: 1.6vw;
				font-size: 2vw;
				font-weight: bold;
				color: #fff;
				background: #d1102d;
				text-align: center;
			}
			
			.sk_contentTop{
				padding: 40px 30px;
				
				.sk_ct_contentDiv{
					width: 100%;
					display: flex;
					
					.sk_ct_cd_div{
						width: 25%;
						text-align: center;
						color: #fff;
						
						.sk_ct_cd_di_title{
							font-size: 0.5vw;
						}
						
						.sk_ct_cd_di_text{
							font-weight: bold;
							font-size: 3vw;
							padding-top: 1vw;
						}
					}
				}
				
				.sk_ct_imgListDiv{
					padding-top: 20px;
					width: 100%;
					justify-content: center;
					
					.sk_ct_ild_div{
						width: 100%;
						position: relative;
						margin-bottom: 20px;
						
						.sk_ct_ild_di_img{
							width: 100%;
						}
						
						.sk_ct_ild_di_content{
							padding: 1vw;
							background: #d1102d;
							color: #fff;
							position: absolute;
							bottom: 0;
							width: 100%;
						}
					}
				}
				
				.sk_ct_button{
					display: flex;
					justify-content: center;
					padding-top: 20px;
					
					.sk_ct_bu_text{
						color: #fff;
						font-size: 12px;
						padding: 10px;
						border-radius: 40px;
						text-align: center;
						margin-top: 20px;
						background: #d1102d;
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						width: 100px;
					}
				}
			}
		}
	}
</style>