<template>
	<div>
		<div style="padding: 40px 200px;">
			<div style="text-align: center;">
				<div style="font-size: 34px;text-align: center;font-weight: bold;line-height: 58px;color: #333333;">PCB制板和服务</div>
				<div style="font-size: 18px;color: #626262;line-height: 28px;">数智化工厂，品质卓越，快速出货，65w+客户专属之选，捷多邦助您开创行业新局面！</div>
				<div style="display: flex;justify-content: center;padding-top: 20px;">
					<div style="display: flex;align-items: center;padding: 5px 10px;border: 1px solid #ececec;margin-right: 10px;">
						<el-image style="width: 1vw;height: 1vw;" :src="require('@/assets/img/special.png')" :fit="'cover'"></el-image>
						<div style="color: #757575;padding-left: 0.5vw;">可PCB打样</div>
					</div>
					
					<div style="display: flex;align-items: center;padding: 5px 10px;border: 1px solid #ececec;margin-right: 10px;">
						<el-image style="width: 1vw;height: 1vw;" :src="require('@/assets/img/special.png')" :fit="'cover'"></el-image>
						<div style="color: #757575;padding-left: 0.5vw;">可批量生产</div>
					</div>
					
					<div style="display: flex;align-items: center;padding: 5px 10px;border: 1px solid #ececec;margin-right: 10px;">
						<el-image style="width: 1vw;height: 1vw;" :src="require('@/assets/img/special.png')" :fit="'cover'"></el-image>
						<div style="color: #757575;padding-left: 0.5vw;">可SMT贴装</div>
					</div>
					
					<div style="display: flex;align-items: center;padding: 5px 10px;border: 1px solid #ececec;">
						<el-image style="width: 1vw;height: 1vw;" :src="require('@/assets/img/special.png')" :fit="'cover'"></el-image>
						<div style="color: #757575;padding-left: 0.5vw;">可BOM配单</div>
					</div>
				</div>
			</div>
		</div>
		
		<div style="background-color: #282828;padding: 0 200px;width: 100%;display: flex;">
			<div style="width: 25%;padding:2vw 1vw;background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#ff6a00, #ff6a00);border-left: 1px solid #363636;">
				<div style="display: flex;align-items: center;">
					<div style="color: #fff;font-weight: bold;font-size: 1.4vw;">双层板</div>
					<div style="color: red;padding: 4px 10px;border-radius: 50px;background: #fff;font-size: 0.7vw;margin-left: 0.5vw;">热销</div>
				</div>
				<div style="display: flex;color: #fff;">
					<div style="font-size: 1vw;line-height: 3.5vw;font-weight: bold;">￥</div>
					<div style="font-size: 2vw;font-weight: bold;">30元</div>
					<div style="font-size: 1vw;line-height: 3.5vw;">/长宽10CM内*5片</div>
				</div>
				
				<div style="padding-top: 0.7vw;color: #fff;">
					<div>产品描述：</div>
					
					<div style="padding-top: 0.7vw;">
						<div style="display: flex;align-items: center;">
							<div style="padding: 0.1vw 0.3vw;color: red;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">FR-4 TG150, 1.6mm</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: red;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">正常交期1~2天</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: red;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">全国包邮</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: red;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">可12/24小时急速出货</div>
						</div>
					</div>
				</div>
				<div style="color: #fff;font-size: 1vw;padding: 0.5vw;border: 1px solid #fff;border-radius: 40px;text-align: center;margin-top: 20px;">特价购买</div>
			</div>
			
			<div style="width: 25%;padding:2vw;border-left: 1px solid #363636;">
				<div style=";width: 100%;background-size: cover;padding:1vw;" :style="'background-image:' + `url(${require('@/assets/img/layer4-icon.png')})`">
					<div style="display: flex;align-items: center;">
						<div style="color: #fff;font-weight: bold;font-size: 1vw;">四层板</div>
						<div style="color: red;padding: 4px 10px;border-radius: 50px;font-size: 0.5vw;margin-left: 0.5vw;border: 1px solid red;color: red;">特价</div>
					</div>
					<div style="font-size: 0.8vw;color: #fff;padding-top: 10px;">长宽10CM内*5片</div>
					<div style="display: flex;color: #fff;">
						<div style="font-size: 0.7vw;padding-top: 0.8vw;">￥</div>
						<div style="font-size: 1.5vw;font-weight: bold;">100元</div>
					</div>
				</div>
				
				<div style="padding-top: 0.7vw;color: #fff;">
					<div>产品描述：</div>
					
					<div style="padding-top: 0.7vw;">
						<div style="display: flex;align-items: center;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">FR-4 TG150, 1.6mm</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">正常交期4~5天</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">全国包邮</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">可24/48小时急速出货</div>
						</div>
					</div>
				</div>
				<div style="color: #fff;font-size: 1vw;padding: 0.5vw;border-radius: 40px;text-align: center;margin-top: 20px;background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);background-blend-mode: normal;box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%)">特价购买</div>
			</div>
			
			<div style="width: 25%;padding:2vw;border-left: 1px solid #363636;">
				<div style=";width: 100%;background-size: cover;padding:1vw;" :style="'background-image:' + `url(${require('@/assets/img/layer6-icon.png')})`">
					<div style="display: flex;align-items: center;">
						<div style="color: #fff;font-weight: bold;font-size: 1vw;">六层板</div>
						<div style="color: red;padding: 4px 10px;border-radius: 50px;font-size: 0.5vw;margin-left: 0.5vw;border: 1px solid red;color: red;">热销</div>
					</div>
					<div style="font-size: 0.8vw;color: #fff;padding-top: 10px;">长宽10CM内*5片</div>
					<div style="display: flex;color: #fff;">
						<div style="font-size: 0.7vw;padding-top: 0.8vw;">￥</div>
						<div style="font-size: 1.5vw;font-weight: bold;">800元</div>
					</div>
				</div>
				
				<div style="padding-top: 0.7vw;color: #fff;">
					<div>产品描述：</div>
					
					<div style="padding-top: 0.7vw;">
						<div style="display: flex;align-items: center;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">FR-4 TG150, 1.6mm</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">正常交期5~6天</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">全国包邮</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">可24/48/72小时急速出货</div>
						</div>
					</div>
				</div>
				<div style="color: #fff;font-size: 1vw;padding: 0.5vw;border-radius: 40px;text-align: center;margin-top: 20px;background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);background-blend-mode: normal;box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%)">特价购买</div>
			</div>
			
			<div style="width: 25%;padding:2vw;border-left: 1px solid #363636;">
				<div style=";width: 100%;background-size: cover;padding:1vw;" :style="'background-image:' + `url(${require('@/assets/img/layer1-icon.png')})`">
					<div style="display: flex;align-items: center;">
						<div style="color: #fff;font-weight: bold;font-size: 1vw;">单面铝基板</div>
						<div style="color: red;padding: 4px 10px;border-radius: 50px;font-size: 0.5vw;margin-left: 0.5vw;border: 1px solid red;color: red;">热销</div>
					</div>
					<div style="font-size: 0.8vw;color: #fff;padding-top: 10px;">长宽10CM内*5片</div>
					<div style="display: flex;color: #fff;">
						<div style="font-size: 0.7vw;padding-top: 0.8vw;">￥</div>
						<div style="font-size: 1.5vw;font-weight: bold;">50元</div>
					</div>
				</div>
				
				<div style="padding-top: 0.7vw;color: #fff;">
					<div>产品描述：</div>
					
					<div style="padding-top: 0.7vw;">
						<div style="display: flex;align-items: center;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">铝基板, 1W, 板厚1.0/1.2/1.6mm</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">正常交期3~4天</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">全国包邮</div>
						</div>
						<div style="display: flex;align-items: center;padding-top: 10px;">
							<div style="padding: 0.1vw 0.3vw;color: #282828;background: #fff;border-radius: 50px;font-size: 10px;">✔</div>
							<div style="color: #fff;padding-left: 10px;">可8/12/24小时急速出货</div>
						</div>
					</div>
				</div>
				<div style="color: #fff;font-size: 12px;padding: 10px;border-radius: 40px;text-align: center;margin-top: 20px;background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);background-blend-mode: normal;box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);width: 100px;">特价购买</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>