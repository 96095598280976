<template>
	<div>
		<div class="makingService" v-if="windowWidth>=1200">
			<div class="ms_titleDiv">
				<div class="ms_td_title">{{$t('boardMaking.pcbBoardMaking.title')}}</div>
				<div class="ms_td_text">{{$t('boardMaking.pcbBoardMaking.text')}}</div>
			</div>
			
			<div class="ms_contentDiv">
				<div class="ms_cd_div" v-for="(item,index) in $t('boardMaking.pcbBoardMaking.list')" :key="index">
					<el-image class="ms_cd_di_img" :src="item.img" :fit="'cover'"></el-image>
					<div class="ms_cd_di_contentDiv">
						<div class="ms_cd_di_cd_title">{{item.title}}</div>
						<div class="ms_cd_di_cd_label">
							<div class="ms_cd_di_cd_lb_text" style="color: #fff;background: #d1102d;">{{item.lable1}}</div>
							<div class="ms_cd_di_cd_lb_text" style="color: #d1102d;border: 1px solid #d1102d;">{{item.lable2}}</div>
						</div>
						<div class="ms_cd_di_cd_content1">
							<!-- <el-image style="width: 0.7vw;height: 0.7vw;" :src="require('@/assets/img/collect.png')" :fit="'cover'"></el-image> -->
							<div class="ms_cd_di_cd_ct1_text">{{item.content1}}</div>
						</div>
						<div class="ms_cd_di_cd_content2">
							<!-- <el-image style="width: 0.7vw;height: 0.7vw;" :src="require('@/assets/img/collect.png')" :fit="'cover'"></el-image> -->
							<div class="ms_cd_di_cd_ct2_text">{{item.content2}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="makingService2" v-if="windowWidth<=1199">
			<div class="ms_titleDiv">
				<div class="ms_td_title">{{$t('boardMaking.pcbBoardMaking.title')}}</div>
				<div class="ms_td_text">{{$t('boardMaking.pcbBoardMaking.text')}}</div>
			</div>
			
			<div class="ms_contentDiv">
				<div class="ms_cd_div" v-for="(item,index) in $t('boardMaking.pcbBoardMaking.list')" :key="index">
					<el-image class="ms_cd_di_img" :src="item.img" :fit="'contain'"></el-image>
					<div class="ms_cd_di_contentDiv">
						<div style="">
							<div class="ms_cd_di_cd_title">{{item.title}}</div>
							<div class="ms_cd_di_cd_label">
								<div class="ms_cd_di_cd_lb_text" style="color: #fff;background: #d1102d;">{{item.lable1}}</div>
								<div class="ms_cd_di_cd_lb_text" style="color: #d1102d;border: 1px solid #d1102d;">{{item.lable2}}</div>
							</div>
							<div class="ms_cd_di_cd_content1">
								<!-- <el-image style="width: 0.7vw;height: 0.7vw;" :src="require('@/assets/img/collect.png')" :fit="'cover'"></el-image> -->
								<div class="ms_cd_di_cd_ct1_text">{{item.content1}}</div>
							</div>
							<div class="ms_cd_di_cd_content2">
								<!-- <el-image style="width: 0.7vw;height: 0.7vw;" :src="require('@/assets/img/collect.png')" :fit="'cover'"></el-image> -->
								<div class="ms_cd_di_cd_ct2_text">{{item.content2}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.makingService{
			padding: 40px 200px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 40px;
				
				.ms_cd_div{
					background: #fff;
					width: 48%;
					float: left;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					margin: 0 0 20px 20px;
					padding: 30px 23px;
					position: relative;
					display: flex;
					
					.ms_cd_di_img{
						width: 40%;
						height: 9vw;
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						width: 60%;
						
						.ms_cd_di_cd_title{
							font-size: 1.2vw;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.makingService{
			padding: 40px 200px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 40px;
				
				.ms_cd_div{
					background: #fff;
					width: 48%;
					float: left;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					margin: 0 0 20px 20px;
					padding: 30px 23px;
					position: relative;
					display: flex;
					
					.ms_cd_di_img{
						width: 40%;
						height: 9vw;
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						width: 60%;
						
						.ms_cd_di_cd_title{
							font-size: 1.2vw;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.makingService{
			padding: 40px 140px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 40px;
				
				.ms_cd_div{
					background: #fff;
					width: 48%;
					float: left;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					margin: 0 0 20px 20px;
					padding: 30px 23px;
					position: relative;
					display: flex;
					
					.ms_cd_di_img{
						width: 40%;
						height: 9vw;
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						width: 60%;
						
						.ms_cd_di_cd_title{
							font-size: 1.2vw;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.makingService{
			padding: 40px 30px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 40px;
				
				.ms_cd_div{
					background: #fff;
					width: 48%;
					float: left;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					margin: 0 0 20px 20px;
					padding: 30px 23px;
					position: relative;
					display: flex;
					
					.ms_cd_di_img{
						width: 40%;
						height: 9vw;
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						width: 60%;
						
						.ms_cd_di_cd_title{
							font-size: 1.2vw;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.makingService2{
			padding: 40px 30px;
			margin-top: 60px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 40px;
				
				.ms_cd_div{
					background: #fff;
					width: 100%;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					margin: 0 0 20px 20px;
					padding: 30px 23px;
					position: relative;
					text-align: center;
					
					.ms_cd_di_img{
						width: calc(100vw - 500px);
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						display: flex;
						justify-content: center;
						
						.ms_cd_di_cd_title{
							font-size: 1.2vw;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							justify-content: center;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							justify-content: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							justify-content: center;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.makingService2{
			margin-top: 60px;
			padding: 40px 30px;
			
			.ms_titleDiv{
				text-align: center;
				
				.ms_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.ms_td_text{
					font-size: 14px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.ms_contentDiv{
				display: flex;
				flex-wrap: wrap;
				padding-top: 20px;
				
				.ms_cd_div{
					background: #fff;
					width: 100%;
					background-color: #fff;
					box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);
					padding: 30px 23px;
					margin-bottom: 20px;
					position: relative;
					text-align: center;
					
					.ms_cd_di_img{
						width: calc(100vw - 200px);
					}
					
					.ms_cd_di_contentDiv{
						padding-left: 1vw;
						display: flex;
						justify-content: center;
						
						.ms_cd_di_cd_title{
							font-size: 12px;
							color: #333333;
						}
						
						.ms_cd_di_cd_label{
							display: flex;
							justify-content: center;
							padding-top: 5px;
							flex-wrap: wrap;
							
							.ms_cd_di_cd_lb_text{
								padding: 1px 5px;
								margin-right: 5px;
								margin-top: 5px;
							}
						}
						
						.ms_cd_di_cd_content1{
							display: flex;
							align-items: center;
							justify-content: center;
							padding-top: 2vw;
							
							.ms_cd_di_cd_ct1_text{
								padding-left: 10px;
								color: #757575;
							}
						}
						
						.ms_cd_di_cd_content2{
							display: flex;
							justify-content: center;
							align-items: center;
							padding-top: 10px;
							
							.ms_cd_di_cd_ct2_text{
								padding-left: 10px;
								color: #757575;
							}
						}
					}
				}
			}
		}
	}
	
</style>