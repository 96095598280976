<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<making-services :windowWidth="windowWidth" :windowHeight="windowHeight"></making-services>
		<!-- <special-offer></special-offer> -->
		<special-processes :windowWidth="windowWidth" :windowHeight="windowHeight"></special-processes>
		<select-koiios :windowWidth="windowWidth" :windowHeight="windowHeight"></select-koiios>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import makingServices from './components/pcbBoardMaking/makingServices.vue';
	import specialOffer from './components/pcbBoardMaking/specialOffer.vue';
	import specialProcesses from './components/pcbBoardMaking/specialProcesses.vue';
	import selectKoiios from './components/pcbBoardMaking/selectKoiios.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components: {
			top,
			makingServices,
			specialOffer,
			specialProcesses,
			selectKoiios,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>